import React from 'react'
import Navbar from '../components/navbar'
import AboutUs from '../components/AboutUs/aboutone'
import { FloatingWhatsApp } from "react-floating-whatsapp";

import Team from '../components/AboutUs/team'
import Faq from '../components/faq'
import Footer from '../components/footer'
import Companyhead from '../components/AboutUs/companyhead'
import LogoOne from "../../static/images/favicon.png";

export default function about() {
  return (
    <div>
         <div>
        <FloatingWhatsApp
          phoneNumber="+91 9930522133"
          accountName="RJ Actuaries & Consultants"
          allowEsc
          allowClickAway
          notification
          notificationSound
          avatar={LogoOne}
        />
      </div>
       <Navbar/> 
       <AboutUs/>
       {/* <Companyhead/> */}
       {/* <Team/> */}
       {/* <Faq/> */}
       <Footer/>
    </div>
  )
}
