import React from "react";
// import Educationfour from '../../../static/images/education4.jpg'
// import P1 from '../../static/images/p1.png'
import Sectiontwoimg from "../../../static/images/aboutimg.png";
import Mission from "../../../static/images/mission.jpg";
import Vission from "../../../static/images/vission.jpg";
import Swargesh from "../../../static/images/Swarg.jpg";
import Tamanna from "../../../static/images/Tam.jpg";
import Ranauk from "../../../static/images/ranu.jpg";
import Icons1 from "../../../static/images/icons1.png";
import Icons2 from "../../../static/images/icons2.png";
import Icons3 from "../../../static/images/icons3.png";

export default function header() {
  return (
    <div>
      <section
        class="py-12 bg-slate-50 text-blue-900"
        style={{ fontFamily: "poppins" }}
      >
        <div class="container mx-auto lg:px-4 p-8 lg:p-10 lg:pl-16">
          <div class="mb-12 md:mb-20 max-w-5xl">
            <h1 class="relative text-3xl sm:text-4xl xl:text-8xl text-blue-900 font-semibold font-heading">
              <span class="mr-3">Innovate, Empower, and Lead</span>
              <span class="relative inline-block">
                {/* <span class="relative z-10"> RJ Actuaries & Consultants</span> */}
                <span class="absolute -bottom-2 left-0 h-2.5 w-full bg-green-100 rounded-full"></span>
              </span>
            </h1>
          </div>
          <h3 class="w-11/12 lg:w-10/12 mb-8 text-xl lg:text-5xl font-light leading-snug text-gray-900 font-heading text-left">
            Since 2020, we've been instrumental in designing employee benefits
            and risk management solutions for some of the most innovative
            companies worldwide.

          </h3>
          <div class="flex flex-wrap -m-3 mb-16">
            <div class="w-full md:w-1/2 xl:w-1/3 p-3">
              <div class="flex flex-col h-full justify-center lg:p-5 bg-white rounded-5xl">
                <div class="flex flex-wrap items-center -m-3 mb-5">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                      <img src={Icons2} alt="" />
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h4 class="text-2xl lg:text-4xl font-medium tracking-tight font-heading">
                      About Us
                    </h4>
                  </div>
                </div>
                <p class="text-neutral-600 font-medium tracking-tight text-gray-800">
                  RJ Actuaries & Consultants is a cutting-edge firm empowering
                  clients with data-driven decisions. Specializing in actuarial,
                  risk management, and innovative solutions, it offers expertise
                  in areas including insurtech, data science, and regulatory
                  compliance.
                </p>
              </div>
            </div>
            <div class="w-full md:w-1/2 xl:w-1/3 p-3">
              <div class="mb-6 lg:p-5 bg-white rounded-5xl">
                <div class="flex flex-wrap items-center -m-3 mb-5">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                      <img src={Icons1} alt="" />
                    </div>
                  </div>
                  <div class="flex-1 p-3 ">
                    <h4 class="text-2xl lg:text-4xl font-medium tracking-tight font-heading">
                      Mission
                    </h4>
                  </div>
                </div>
                <p class="text-neutral-600 font-medium tracking-tight text-gray-800">
                  RJAC's mission is to Empower and Illuminate clients, providing
                  them with knowledge and resources to forge their own paths to
                  success. With special frameworks and solutions, RJAC ensures
                  secure, long-term returns, positioning itself as a strategic
                  partner through a modernized approach.
                </p>
              </div>
              <div class="lg:p-10 bg-white rounded-5xl">
                <div class="flex flex-wrap items-center -m-3 mb-5">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                      <img src={Icons3} alt="" />
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h4 class="text-2xl lg:text-4xl font-medium tracking-tight font-heading">
                      Vision
                    </h4>
                  </div>
                </div>
                <p class="text-neutral-600 font-medium tracking-tight text-gray-800">
                  RJAC's vision is to become a leading provider of Actuarial,
                  Employee Benefits, Risk Management, and Business Strategy
                  services, leveraging the latest technology and data science to
                  deliver innovative solutions to its clients.
                </p>
              </div>
            </div>
            <div class="w-full xl:w-1/3 p-3">
              <div class="flex flex-col h-full justify-center lg:p-10 bg-white rounded-5xl">
                <div class="flex flex-wrap items-center -m-3 mb-5">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                      <img src={Icons2} alt="" />
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h4 class="text-2xl lg:text-4xl font-medium tracking-tight font-heading">
                      Our Story
                    </h4>
                  </div>
                </div>
                <p class="text-neutral-600 font-medium tracking-tight text-gray-800">
                  Established in 2020 by Rounak Jha, RJ Actuaries & Consultants
                  provides bespoke actuarial, risk management, and data science
                  services globally, empowering clients with data-driven
                  insights for informed decision-making in the insurance and
                  risk management sectors.
                </p>
              </div>
            </div>
          </div>
          {/* <div class="flex flex-wrap justify-center">
      <div class="w-auto"><a class="inline-flex justify-center items-center text-center h-20 p-5 font-semibold tracking-tight text-2xl text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200" href="#">Get consultation</a></div>
    </div> */}
        </div>
      </section>

      {/* <section
        class=" lg:pb-16 md:px-6 bg-white mt-20"
        style={{ fontFamily: "Poppins" }}
      >
      


        <div class="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
          <div class="flex flex-col lg:flex-row justify-between gap-8">
            <div class="w-full lg:w-5/12 flex flex-col justify-center">
              <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4">
                About Us Content
              </h1>
              <p class="font-normal text-base leading-6 text-black dark:text-white">
                RJ Actuaries & Consultants is a New Age Actuarial and Risk
                Management Consulting Firm that empowers its clients to make
                data-driven decisions. The company is driven by a passion to
                help and is committed to empowering clients to drive their own
                success. With a team of experienced Qualified Actuaries and Data
                Scientists, RJ Actuaries & Consultants offers a range of
                specialized services, including actuarial valuation, employee
                benefits, business consulting, technology solutions, data
                mining, machine learning, and AI. The company's specialties
                include actuarial, strategic consulting, insurtech, risk
                management, data science, analytics, IFRS 17, regulatory
                compliance, general insurance, health insurance, insurance
                product development, and capital and solvency assessment.
              </p>
            </div>
            <div class="w-full lg:w-8/12">
              <img
                class="w-full h-full"
                src={Sectiontwoimg}
                alt="A group of People"
              />
            </div>
          </div>

          <div class="flex lg:flex-row flex-col justify-between gap-8 pt-12">
            <div class="w-full lg:w-5/12 flex flex-col justify-center">
              <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4">
                Our Story
              </h1>
              <p class="font-normal text-base leading-6 text-gray-600 dark:text-white">
                Founded in 2020 by Raunak Jha, a Qualified Health and General
                Insurance Actuary from India and the UK, RJ Actuaries &
                Consultants has quickly established itself as a customer-centric
                enterprise, delivering quality and professionally compliant
                valued services to its clients. The company's founder has over
                19 years of work experience, leading and steering Actuarial,
                Risk Management, Audit, Compliance, and Data Science projects
                across various markets, including India, the Middle East,
                Singapore, the UK, and the US.
              </p>
            </div>
            <div class="w-full lg:w-8/12 lg:pt-8">
              <div class="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg">
                <div class="p-4 pb-6 flex justify-center flex-col items-center  ">
                  <img className="rounded-md" src={Ranauk} />

                  <p class="font-medium text-sm leading-5 text-gray-800 dark:text-white mt-4">
                    Raunak Jha
                  </p>
                </div>
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                  <img src={Tamanna} />
               
                  <p class="font-medium text-sm leading-5 text-gray-800 dark:text-white mt-4">
                    Tamanna Goel
                  </p>
                </div>
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                  <img src={Swargesh} />
           
                  <p class="font-medium text-sm leading-5 text-gray-800 dark:text-white mt-4 text-center">
                    Swargesh Tripathi
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="mx-10 md:mx-20">
          <p>
            RJ Actuaries & Consultants is committed to helping its clients
            strategize their path to success by empowering them with data-driven
            solutions. The company believes in empowering its clients with the
            right knowledge and tools to help them steer their own path to
            success. With a focus on transparency and deep analysis, RJ
            Actuaries & Consultants provides bespoke solutions for new age
            problems, enabling its clients to make informed and meaningful
            business decisions. In summary, RJ Actuaries & Consultants is a
            forward-thinking consulting firm that leverages its team's expertise
            in actuarial science, risk management, and data science to provide
            tailored solutions for its clients. The company's commitment to
            empowering clients and its focus on data-driven decision-making set
            it apart as a valuable partner for businesses seeking to navigate
            the complexities of the insurance and risk management landscape.
          </p>
        </div>
        <div className="mx-10 md:mx-16">
          <div class="container md:px-5 py-24">
            <div class="flex flex-wrap -mx-4 -mb-10 text-center">
              <div class="sm:w-1/2 mb-10 md:px-4">
                <div class="rounded-lg h-64 overflow-hidden">
                  <img
                    alt="content"
                    class="object-cover object-center h-full w-full"
                    src={Mission}
                  />
                </div>
                <h2 class="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">
                  Core Mission
                </h2>
                <p class="leading-relaxed text-base text-justify">
                  RJAC’s core mission is to Empower and Illuminate. We think
                  it's important to arm our customers with the knowledge and
                  resources they need to forge their own paths to success. RJAC
                  is adamant about providing its clients with the information
                  and resources they need to forge their own paths to success.
                  With this conviction, it has created special frameworks and
                  solutions to assist its clients in achieving upside potential
                  returns in a secure and long-term manner. RJAC has advanced in
                  its quest to become a chosen option as a strategic partner for
                  its clients thanks to a modernised but strategic approach.
                </p>
              </div>
              <div class="sm:w-1/2 mb-10 px-4">
                <div class="rounded-lg h-64 overflow-hidden">
                  <img
                    alt="content"
                    class="object-cover object-center h-full w-full"
                    src={Vission}
                  />
                </div>
                <h2 class="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">
                  Vision
                </h2>
                <p class="leading-relaxed text-base text-justify">
                  RJAC's vision is to become a leading provider of Actuarial,
                  Employee Benefits, Risk Management, and Business Strategy
                  services, leveraging the latest technology and data science to
                  deliver innovative solutions to its clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}
